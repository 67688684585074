import React from 'react';
import './HistoryBlock.css';

const HistoryBlock = ({title, items, past}) => {
	// sort out the history
	items.sort((a, b) => b.year - a.year);
	items.sort((a, b) => b.yearBegin - a.yearBegin);
	//
	if (items[0].yearEnd === "now") {
		var today = new Date();
		items[0].yearEnd = today.getFullYear();
		// var now = items[0].yearEnd - items[0].yearBegin == 0 ? "now" : "";
	}
	// create React objects
	var i = 0;
	var historyItems = items.map((listItem) =>
	  listItem.year ? (
		  <div id={listItem.year} className="History-item" key={listItem.year}>
			  <h4>{listItem.header} <span className="History-term">{listItem.year}</span></h4>
			  <p>
				  <span className="Job-title Normal-text">{listItem.title}</span>{listItem.description ? ' in ' : ''}<span className="Normal-text">{listItem.description}</span>
			  </p>
		  </div>
	  ) : (
		  <div id={listItem.yearBegin} className="History-item" key={i++}>
			  <h4>{listItem.header} <span className="History-term">{listItem.yearEnd - listItem.yearBegin} year{listItem.yearEnd - listItem.yearBegin > 1 ? 's' : ''}</span></h4>
			  <p className="Normal-text">
				  {listItem.description}
			  </p>
		  </div>
	  )
	);
	if (past) {
		historyItems.push(
			<div id="before" className="History-item" key={i++}>
				<h4><span className="History-term" style={{display: "inherit"}}>…</span></h4>
			</div>
		);
	}
	return (
		<div className="Block" id={title}>
			<h3>{title}</h3>
			{historyItems}
		</div>
	);
};

export default HistoryBlock;
