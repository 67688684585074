import { Outlet, Link, NavLink } from "react-router-dom";
import { RiUser6Line } from 'react-icons/ri';
import { IconContext } from "react-icons";

import ContactBlock from '../components/ContactBlock/';
import ProfilePictureBlock from '../components/ProfilePictureBlock/';

export default function Layout() {
  const iconStyle = { className: "Icon", size: "1.15em", title: "Home" };
  return (
    <div className="Home">
      <header className="Home-header">
        <Link to="/">
          <ProfilePictureBlock image="./pavsk.jpg" />
        </Link>
        <div>
          <div id="Title">
            <h1>Pavel Sk.</h1>
            <h2>IT Business Partner / Product Manager / BA</h2>
            <nav>
              <ul>
                <li><NavLink to="/" title="Home"><IconContext.Provider value={iconStyle}><RiUser6Line /></IconContext.Provider></NavLink></li>
                  {/* <ul>
                    <li><NavLink to="/expertise" title="Expertise">Exp</NavLink></li>
                    <li><NavLink to="/service">Service</NavLink></li>
                  </ul> */}
                <li><NavLink to="/request-for-information" title="Request for information">Request</NavLink></li>
                {/* TODO: <li><Link to="/blog">Blog</Link></li> */}
                <li><NavLink to="/cv">CV</NavLink></li>
              </ul>
            </nav>          
          </div>
          
        </div>
        
      </header>
      
      <Outlet />
      
      <footer className="Home-footer">
        <ContactBlock />
      </footer>
    </div>
  );
}