import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Layout, Contact, CV, Welcome, Area, Service } from './pages';
import { Expertise, Services } from "./pages";

const router = createBrowserRouter(
    [{
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Welcome />
        },
        {
          path: "cv",
          element: <CV />
        },
        {
          path: "request-for-information",
          element: <Contact />
        }
      ]
    },
    {
      path: "/expertise/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Expertise />
        },
        {
          path: "business-analysis",
          element: <Area />
        }
      ]
    },
    {
      path: "service/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Services />
        },
        {
          path: "consulting",
          element: <Service />
        }
      ]
    },
    {
      path: "*",
      element: <Layout />
    }]
  );

export default function AppRouter() {
  return (
    <RouterProvider router={router} />
  );
}
