// import React from 'react';
import { Link } from "react-router-dom";
import TextBlock from '../components/TextBlock';

export default function Services() {
  return (
    <main className="Home-body">
      <h2>Services</h2>

      <div className="Columns">
        <div className="Left-side"> 
          <TextBlock title="Consulting" text={[<Link to="/service/consulting">Read more</Link>]} />
        </div>
        <div className="Right-side"> 
          <TextBlock title="Transformation Facilitation" text={[<Link to="/service/transformation">Read more</Link>]} />
        </div>
      </div>

    </main>
  );
}