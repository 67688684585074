import React from 'react';
import './ProfilePictureBlock.css';

const ProfilePictureBlock = ({image}) => {
    return (
        <div id="ProfilePicture">
            <img className="ProfilePicture" src={image} alt="Pavel Sk. portrait" />
        </div>
    );
}

export default ProfilePictureBlock;
