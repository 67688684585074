import React from 'react';

const TextBlock = ({title, text}) => {
	var i = 0;
	const paragraphs = text.map((paragraph) =>
		<p key={{title} + i++}>{paragraph}</p>
	);
	return (
		<div className="Block" id={title}>
			<h3>{title}</h3>
			{/* <p>{text}</p> */}
			{paragraphs}
		</div>
	);
};

export default TextBlock;
