// import React from 'react';
import { Link } from "react-router-dom";
// import TextBlock from '../components/TextBlock';

export default function Welcome() {
  return (
    <main className="Home-body">
      <h2>Welcome</h2>
      
      <p>At your service an IT professional with a proven track of accomplishments as a leader, a crisis project manager and an advisor in software delivery for banks and other clients worldwide.</p>
      
      {/* <p className="subheading">Expertise</p>
      <div className="Columns">
        <div className="Left-side"> 
          <TextBlock title="Business Analysis" text={["test", <Link to="/expertise/business-analysis">More</Link>]} />
          <TextBlock title="Delivery Management" text={["test", <Link to="/expertise/delivery-management">More</Link>]} />
        </div>
        <div className="Right-side"> 
          <TextBlock title="Product Management" text={[<Link to="/expertise/product-management">Check out</Link>]} />
          <TextBlock title="Change Management" text={[<Link to="/expertise/change-management">Details</Link>]} />
        </div>
      </div>
      
      <p className="subheading">Services</p>
      <div className="Columns">
        <div className="Left-side"> 
          <TextBlock title="Consulting" text={[<Link to="/service/consulting">Read more</Link>]} />
        </div>
        <div className="Right-side"> 
          <TextBlock title="Transformation Facilitation" text={[<Link to="/service/transformation">Read more</Link>]} />
        </div>
      </div> */}

      <p>Take a look to my <Link to="/cv">curriculum vitae's page</Link> to learn my story in short.</p>

      <p>Since I've got relevant expertise, I offer consulting and digital transformation services. Reach my out via the <Link to="/request-for-information">request-for-information form</Link>, if you're interested.</p>

    </main>
  );
}