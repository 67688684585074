// import React from 'react';
import { Link } from "react-router-dom";
import TextBlock from '../components/TextBlock';

export default function Expertise() {
  return (
    <main className="Home-body">
      <h2>Expertise</h2>
      
      <div className="Columns">
        <div className="Left-side"> 
          <TextBlock title="Business Analysis" text={["test", <Link to="/expertise/business-analysis">More</Link>]} />
          <TextBlock title="Delivery Management" text={["test", <Link to="/expertise/delivery-management">More</Link>]} />
        </div>
        <div className="Right-side"> 
          <TextBlock title="Product Management" text={[<Link to="/expertise/product-management">Check out</Link>]} />
          <TextBlock title="Change Management" text={[<Link to="/expertise/change-management">Details</Link>]} />
        </div>
      </div>
    </main>
  );
}