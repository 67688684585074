// import React from 'react';
import ContactForm from "../components/ContactForm";

export default function Contact() {
    return (
        <div className="Home-body">
        <h2>Contact</h2>
        <ContactForm />
        </div>
    );
}