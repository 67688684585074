import React from 'react';
import './ContactBlock.css';
import { RiMailLine, RiTelegramFill, RiCellphoneFill, RiLinkedinBoxFill, RiGithubFill, RiMastodonFill } from 'react-icons/ri';
import { IconContext } from "react-icons";

const contacts = [
  //{key: 'mobile', value: ''},
  //{key: 'email', value: 'pavel+sk@discreto.art'},
  {key: 'telegram', value: '@pavsk'},
  {key: 'linkedin', value: 'pavsk'},
//   {key: 'github', value: 'xyberty'},
  {key: 'mastodon', value: '@pavsk'}
];

const ContactBlock = () => {
	const refs = contacts.map((listItem) =>
		{
			var proto;
			// eslint-disable-next-line
			switch (listItem.key) {
				case 'email':
					proto = 'mailto:';
					break;
				case 'mobile':
					proto = 'tel:';
					break;
				case 'telegram':
					proto = 'https://t.me/';
					break;
				case 'linkedin':
					proto = 'https://www.linkedin.com/in/';
					break;
				case 'github':
					proto = 'https://github.com/';
					break;
				case 'mastodon':
					proto = 'https://mas.to/@';
					break;
			}
			return proto.concat(listItem.value.replace(/^@/, ''));
		}
	);
	const iconStyle = { className: "Icon", size: "1.2em" };
	const listItems = contacts.map((listItem, i) =>
	  <li key={listItem.key}>
		{(listItem.key === 'e-mail') ? <IconContext.Provider value={iconStyle}><RiMailLine/></IconContext.Provider>
			: (listItem.key === 'mobile') ? <IconContext.Provider value={iconStyle}><RiCellphoneFill/></IconContext.Provider>
				: (listItem.key === 'telegram') ? <IconContext.Provider value={iconStyle}><RiTelegramFill/></IconContext.Provider>
					: (listItem.key === 'linkedin') ? <IconContext.Provider value={iconStyle}><RiLinkedinBoxFill/></IconContext.Provider>
						: (listItem.key === 'github') ? <IconContext.Provider value={iconStyle}><RiGithubFill/></IconContext.Provider>
							: (listItem.key === 'mastodon') ? <IconContext.Provider value={iconStyle}><RiMastodonFill/></IconContext.Provider>
							: ''
		}
		<a href={refs[i]} className="Alternative-link" rel="me noopener external" title={listItem.key + " profile"}>{listItem.value}</a>
	  </li>
	);

	return (
		<div id="contacts">
			<ul>
				{listItems}
			</ul>
  		</div>
	);
};

export default ContactBlock;