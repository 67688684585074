// import React from 'react';
import TextBlock from '../components/TextBlock/';
import ListBlock from '../components/ListBlock/';
import HistoryBlock from '../components/HistoryBlock/';
import SkillsBlock from '../components/SkillsBlock/';

export default function CV() {
  const selfPresentation = [
      "An IT business partner with experience in the field since 2010, and broad mind in different business and technology domains. Have been successfully played Business Analyst, Product Owner, Consultant and Scrum Master roles since 2017 on 18 projects for 7 customers in 5 business domains.",
      "Coordinated projects and led by example various teams of 3–20 people. Effectively interacted with project sponsors, senior management, end users, delivery managers, architects, engineers and designers in different engagement models.",
      "Produced high-quality artefacts: audit reports, product vision, change strategy, etc."
  ];
  const skills = [
      {value: 'banking, telco, e-commerce and GRC business domains'},
      {value: 'product management, product ownership, team leadership'},
      {value: 'business & systems analysis, enterprise analysis, solution design'},
      {value: 'data modelling, data analysis, profiling and integration'},
      {value: 'presentation, moderation and facilitation'},
      {value: 'people coaching and mentoring, onboarding and training'},
      {value: 'knowledge management'},
      {value: 'continuous self-development and fast-learning'},
      {value: 'Kanban method, Scrum framework, scaled agile'},
      //{value: 'ArchiMate, UML; SQL, XML, Python'},
  ];
  const education = [
      {year: 2008, header: 'Russian State University of Tourism & Service', title: 'Specialist', description: 'Service of Computing and micro-processing technology' },
      {year: 2004, header: 'Volgograd Polytechnic College', title: 'Technician', description: 'Computing systems and networks' },
      {year: 2022, header: 'Centro Adelante', title: 'B2', description: 'Spanish' },
  ];
  const certificates = [
      {year: 2021, header: "TM Forum", title: "Business Development Manager, Frameworx Transformation Manager" },
      {year: 2019, header: "UiPath", title: "Business Analyst (RPA)" }
  ]
  const career = [
      {yearBegin: 2010, yearEnd: 2011, header: 'Otkritie Bank, RU', description: 'Software development, Systems migration, Configuration' },
      {yearBegin: 2011, yearEnd: 2013, header: 'CSBI Group, RU', description: 'Software customisation, Software development (PL/SQL)' },
      {yearBegin: 2013, yearEnd: 2019, header: 'Bank of Saint-Petersburg, RU', description: 'Project coordination, Team leadership, Business analysis, Solutions design' },
      {yearBegin: 2019, yearEnd: 2023, header: 'EPAM Systems', description: 'Consulting, Business analysis, Enterprise strategy analysis, Product management, Data & BI analysis, Delivery management, Mentoring' },
      {yearBegin: 2023, yearEnd: "now", header: "Independent Consultant", description: "Software delivery audit and governance, Transformation facilitation"}
  ];
  const skillLevels = [
      {title: 'Agile & Lean', level: 5},
      {title: 'Scaled Agile', level: 2},
      {title: 'Product Management', level: 3},
      {title: 'Project Management', level: 3},
      {title: 'Analysis', level: 5},
      {title: 'Data', level: 3},
      {title: 'Architecture', level: 2},
      {title: 'Engineering', level: 3},
      {title: 'Version Control', level: 3},
      //{title: 'Jira/Confluence', level: 5},
  ];
  const languageLevels = [
      {title: 'Russian ', level: 5, comment: 'Native', image: './ru.png'},
      {title: 'English', level: 3, comment: 'B2+', image: './en.png'},
      {title: 'Spanish', level: 3, comment: 'B2', image: './es.png'},
  ];

  return (
    <div className="Home-body">
    <h2>Curriculum vitae</h2>
    <div className="Columns">
        <div className="Left-side">
        <TextBlock title="Profile" text={selfPresentation} />
        <ListBlock title="Personal Skills" items={skills} />
        <SkillsBlock title="Skills" items={skillLevels} />
        </div>
        <div className="Right-side">
        <HistoryBlock title="Education" items={education} />
        <HistoryBlock title="Certificates" items={certificates} />
        <HistoryBlock title="Experience" items={career} past="yes" />
        <SkillsBlock title="Languages" items={languageLevels} />
        </div>
    </div>
    </div>
  );
}