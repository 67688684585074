import React from 'react';
import './SkillsBlock.css';
import Skill from './Skill/';

const SkillsBlock = ({title, items}) => {
	var i = 0;
	const skillItems = items.map((skill) =>
		<Skill title={skill.title} level={skill.level} comment={skill.comment} img={skill.image} key={i++} />
		);
	return (
		<div className="Block" key={title}>
			<h3 className="Outlined">{title}</h3>
			{skillItems}
		</div>
		);
};

export default SkillsBlock;