import React from 'react';

const ListBlock = ({title, items}) => {
	var i = 0;
	const listItems = items.map((listItem) =>
	  listItem.key ? (
		  <li key={listItem.key}>
		  	<strong>{listItem.key}</strong>: {listItem.value}
		  </li>
	  ) : (
	  	<li key={i++}>
	  		{listItem.value}
	  	</li>
	  )
	);
	return (
		<div className="Block" key={title}>
			<h3>{title}</h3>
			<ul>
				{listItems}
			</ul>
		</div>
	);
};

export default ListBlock;
