import React from 'react';
import './ContactForm.css';

const ContactForm = () => {
    const contactForm = (
        <form id="contact" className="Form" name="contact" method="POST">
            <input type="hidden" name="form-name" value="contact" />
            <div className='Form-group'>
                <label htmlFor='Name'>Your Name</label>
                <input id="Name" type="text" name="name" placeholder="Name" required/>
            </div>
            <div className='Form-group'>
                <label htmlFor='Email'>Your Email</label>
                <input id="Email" type="email" name="email" placeholder="email@address.com" required/>
            </div>
            <div className='Form-group'>
                <label htmlFor='MessageBody'>Request for Information</label>
                <textarea id="MessageBody" name="message" placeholder="Your message" required></textarea>
            </div>
            <div className='Form-button'>
                <button type="submit">Send</button>
            </div>
        </form>
    );

    return (
        <div id="ContactForm">
            {contactForm}
        </div>
        
    );
}

export default ContactForm;