import React from 'react';
import LevelDot from './LevelDot';

const Skill = ({title, level, comment, img}) => {
	const maxLevel = 5;
	var dots = [];
	var icon;
	for (var i = 0; i < maxLevel; i++) {
		dots[i] = 
			(level > 0) ? <LevelDot active="true" key={i} />
				: <LevelDot active="false" key={i} />
				;
		level--;
	}
	if (img) icon = <img src={img} alt={title} width="14px" height="14px" />;
	return (
		<div className="Skill-row" key={title}>
			<div className="Skill-cell">
				{icon} <span title={comment}>{title}</span>
			</div>
			<div className="Level-cell">
				{dots}
			</div>
		</div>
		);
};

export default Skill;